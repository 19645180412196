.SingleArticleTitle {
    color: #0C3FA8;
    font-size: 25px;
    margin-bottom: 10px;
}

.SingleArticleText {
    text-align: justify;
    font-size: 16px;
}

.SingleArticleText img{
    width: 100% !important;
}

.SingleArticleDate {
    font-size: 12px;
    color: #0C3FA8;
}

.SingleArticleImage {
    float: left;
    margin-right: 20px;
}