.footer {
    color: #ffff;
    height: $FooterH;
    padding-top: 35px;
    background-color: $FooterBgColor;
}

.social {
    width: 35px;
    float: left;
    padding: 1px;
    margin-left: 20px;
    text-align: center;
    // border-radius: 100%;
    // border: 2px solid white;
}

.BottomText {
    bottom: 0;
    height: 100%;
    margin-top: 70px;
    text-align: center;
}

.FooterContact {
    padding: 0;
    margin-top: 25px;
}

.FooterMargin {
    margin: 0 5%;
}

@media(max-width:1000px) {
    .footer {
        height: auto;
        color: #ffff;
        padding-top: 35px;
        background-color: $FooterBgColor;
    }

    .BottomText {
        bottom: 0;
        height: 100%;
        margin-top: 0;
        text-align: center;
    }

    .Social {
        margin: 25px;
    }
}

@media(max-width:800px) {
    .FooterMargin {
        margin: 0 10%;
    }

    .FooterContact {
        padding: 0;
        margin-top: 25px;
    }
}

@media(max-width:500px) {
    .footer {
        height: auto;
        color: #ffff;
        font-size: 12px;
        padding-top: 35px;
        background-color: $FooterBgColor;
    }

    .FooterMargin {
        margin: 10px 10%;
    }

    .BottomText {
        bottom: 0;
        height: 100%;
        margin-top: 0;
        text-align: center;
    }

    .FooterContact {
        padding: 0 16%;
        margin-top: 25px;
    }

    .Social {
        margin: 25px;
    }
}