.container {
  /* display: flex; */
  margin: 0 auto;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1140px !important;
  }
}
/* ----------- */
/* ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

li a,
.dropbtn {
  display: inline-block;
  color: #f1f1f1;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: 600;
}

li a:hover,
.dropdown:hover .dropbtn {
  color: #f1f1f1;
}

li.dropdown {
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #005baa;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
    position: unset !important;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover a {
  background-color: #005baa;
}

.dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.dropdown:hover .dropdown-content {
  display: block;
}

li a:hover,
.dropdown:hover .dropbtn {
  color: #f1f1f1;
  background-color: rgba(255, 255, 255, 0.3);
}
*/
/* ------------------------------------------- */
.bg {
  color: white;
  background: #005baa !important;
}

html {
  font-family: "Roboto";
}

.image_post {
  padding-bottom: calc(0.66 * 100%);
  background-repeat: no-repeat !important;
  background-position: top !important;
  background-size: 100% !important;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 0 !important;
}
ol, ul {
  padding-left: 0 !important;
}
/* Style the navigation menu */
.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
  display: none;
}

/* Style navigation menu links */
.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

/* Style the hamburger menu */
.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/*  */

#nav {
  list-style: none inside;
  margin: 0;
  padding: 0;
}

#nav li {
  display: block;
  position: relative;
  float: left;
  /* menu background color */
}

#nav li a {
  width: 100%;
  display: inline-block;
  color: #f1f1f1;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: 600;
  /* white-space: nowrap; */
}
/* 
#nav li li a {
  font-size: 80%;
} */

/* smaller font size for sub menu items */

#nav li:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

/* highlights current hovered list item and the parent list items when hovering over sub menues */

#nav ul {
  display: none;
  position: absolute;
  background-color: #005baa;
  width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

#nav li:hover ul ul {
  display: none;
}

/* hides sub-sublists */

#nav li:hover ul {
  display: block;
}

/* shows sublist on hover */

#nav li li:hover ul {
  display: block;
  margin-left: 250px;
  margin-top: -50px;
}

li a:hover,
.dropdown:hover .dropbtn {
  color: #f1f1f1;
  background-color: rgba(255, 255, 255, 0.3);
}

.active {
  background-color: rgba(255, 255, 255, 0.3);
}
.active a {
  color: white;
} 

.menuNone{
  display: none;
}