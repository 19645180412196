.CardMenu {
  margin-top: 50px;
}

.CardMenuR {
  border-color: 1px white;
  background-color: #0C3FA8;
}

.CardMenuC {
  width: 50%;
  height: 170px;
  float: left;
  display: flex;
  color: white;
  // padding: 25px;
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
  flex-direction: column;
  border: 1px solid white;
  border-color: 1px white;
  background-color: #0C3FA8;
}

.menuImageCard {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 34%;
}

.menusTextCard {
  font-size: 14px;
}
@media (max-width: 1000px) {
  .menusTextCard {
    font-size: 12px;
  }
}
// Single Article Pages CardMenu

.CardMenuCardSingle {
  align-items: center;
  margin: 0 20px;
  width: 100%;
  height: 170px;
  float: left;
  display: flex;
  color: white;
  // padding: 25px;
  font-size: 14px;
  text-align: center;
  border-radius: 3px;
  flex-direction: column;
  border: 1px solid white;
  border-color: 1px white;
  background-color: #0C3FA8;
}

@media(max-width:1000px) {

  .CardMenuCardSingle {
    align-items: center;
    margin: 0;
    width: 100%;
    height: 170px;
    float: left;
    display: flex;
    color: white;
    // padding: 25px;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    flex-direction: column;
    border: 1px solid white;
    border-color: 1px white;
    background-color: #0C3FA8;
  }
}

.CardMenuSingle {
  margin-top: 50px;
}

.CardMenuRowSingle {
  border-color: 1px white;
  background-color: #0C3FA8;
}

.menuImageCardSingle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.menusTextCardSingle {
  width: 80%;
  font-size: 14px;
}
