.Carousel_Link {
    left: 0 !important;
    bottom: 0 !important;
    z-index: 1 !important;
    width: 95%;
    color: white !important;
    padding-left: 10px;
    position: absolute !important;
    text-transform: uppercase;
    background-color: rgba(5, 26, 93, 0.48);
}

.Carousel_Link:hover {
    cursor: pointer;
    text-transform: uppercase;
}

.Carousel {
    height: 400px;
    position: relative;
}

.Carousel_I {
    height: 400px;
    position: relative;
    background-size: cover;
    border: 1px solid white;
    background-position: center top;
    background-repeat: no-repeat;
}

.Cards {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    height: 200px;
    position: relative;
    background-size: cover;
    border: 1px solid white;
    background-position: center top;
    background-repeat: no-repeat;
}

.Card_Text {
    bottom: 0;
    width: 100%;
    padding: 10px;
    color: white;
    font-size: 15px;
    position: absolute;
    text-transform: uppercase;
    background-color: rgba(5, 26, 93, 0.48);
}

.CaptionText {
    float: left;
    text-align: left;
}

.loader {
   position: absolute;
   left: 50%;
   top: 50%;
   width: 120px;
   height: 120px;
   margin: -76px 0 0 -76px;
   border: 16px solid #f3f3f3;
   border-radius: 50%;
   border-top: 16px solid #3498db;
   -webkit-animation: spin 2s linear infinite;
   animation: spin 2s linear infinite;
 }
 
 /* Safari */
 @-webkit-keyframes spin {
   0% { -webkit-transform: rotate(0deg); }
   100% { -webkit-transform: rotate(360deg); }
 }
 
 @keyframes spin {
   0% { transform: rotate(0deg); }
   100% { transform: rotate(360deg); }
 }
@media(max-width: 500px) {
    .Carousel_Link {
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 95%;
        color: white;
        font-size: 12px;
        padding-left: 10px;
        position: absolute;
        text-transform: uppercase;
        background-color: rgba(5, 26, 93, 0.48);
    }

    .Carousel_Link:hover {
        cursor: pointer;
        text-transform: uppercase;
    }

    .Carousel {
        height: 300px;
        position: relative;
    }

    .Carousel_I {
        height: 300px;
        position: relative;
        background-size: cover;
        border: 1px solid white;
        background-position: center top;
        background-repeat: no-repeat;
    }

    .Cards {
        margin: 0;
        padding: 0;
        width: 100%;
        float: left;
        height: 200px;
        position: relative;
        background-size: cover;
        border: 1px solid white;
        background-position: center top;
        background-repeat: no-repeat;
    }

    .Card_Text {
        bottom: 0;
        width: 100%;
        padding: 10px;
        color: white;
        font-size: 12px;
        position: absolute;
        text-transform: uppercase;
        background-color: rgba(5, 26, 93, 0.48);
    }

    .CaptionText {
        float: left;
        text-align: left;
    }
}