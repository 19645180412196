.BottomArticleName {
    margin: 10px 0;
    color: white;
    width: 100%;
    padding: 10px;
    background-color: #4557ff;
}

.BottomArtilceImage {
    width: 100%;
    height: 200px;
    padding: 0;
    margin: 0;
}

.BottomArticlePost {
    background-size: cover;
    justify-content: center;
    background-position: center top;
    background-repeat: no-repeat;
    background-color: white;
}
.BottomArtilceTitle{
    height: 50px;
    padding: 5px;
    font-size: 15px;
    color: $textcolor;
    text-transform: uppercase;
}

.BottomArtilceDate{
    font-size: 12px;
}

.BottomArtilce{
    background: white;
    box-shadow: 0 0 5px gray;
} 