@media(max-width: 2200px) {
    .Tabs {
        background-color: white;
        background-color: gray;
    }

    .MainArticle {
        min-width: 400px;
        margin-top: 30px;
    }

    .Articles_Body_Width {
        width: 50%;
    }

    .images {
        float: left;
        width: 190px;
        height: 120px;
        margin-right: 10px;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
    }

    .ArticleText {
        font-size: 15px;
        color: #0C3FA8;
        text-transform: uppercase;
    }

    .ArticleDate {
        bottom: 0;
        position: absolute;
    }
}

@media(max-width: 1000px) {
    .Tabs {
        background-color: white;
        background-color: gray;
    }

    .MainArticle {
        min-width: 200px;
        margin-top: 30px;
    }

    .Articles_Body_Width {
        width: 100%;
    }

    .Articles_Body_Width img {
        width: 60%;
    }

    .images {
        float: none;
        width: 100%;
        height: 300px;
        margin-right: 10px;
    }

    .ArticleText {
        font-size: 10px;
        color: #0C3FA8;
    }

    .ArticleDate {
        bottom: 0;
        position: unset;
    }
}

@media(max-width: 500px) {
    .images {
        float: none;
        width: 100%;
        height: 250px;
        margin-right: 10px;
    }
}