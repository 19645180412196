.top {
    height: $TopH;
    background: $TopBgColor;
}

.body {
    height: $BodyH;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url($url+"/assets/images/statistics.jpg");
}

.hover {
    width: 100%;
    height: 100%;
    background: $BodyBgColor;
}

.IndexLogo {
    z-index: 1;
    margin: 10px;
    width: $LogoHW;
    margin-left: 30%;
}

@media(max-width: 1100px) {
    .IndexLogo {
        z-index: 1;
        margin: 10px;
        width: $LogoHW;
        margin-left: 46%;
    }
}

@media(max-width: 800px) {
    .IndexLogo {
        z-index: 1;
        margin: 10px;
        width: $LogoHW;
        margin-left: 44%;
    }
}

@media(max-width: 500px) {
    .IndexLogo {
        z-index: 1;
        margin: 10px;
        width: $LogoHW;
        margin-left: 39%;
    }
}

@media(max-width: 350px) {
    .IndexLogo {
        z-index: 1;
        margin: 10px;
        width: $LogoHW;
        margin-left: 35%;
    }
}

.bottom {
    top: 0;
    z-index: 10;
    height: $MenuH;
    position: sticky;
    background: $BottomBgColor;
}

.Menu {
    height: $MenuH;
}

.MenuHover:hover {
    border-radius: 4px;
    background: #ffffff12;
}

.navbar-dark .navbar-nav .nav-link {
    color: "rgb(255 255 255) !important";
}

.Menu a {
    color: white !important;
    text-decoration: none;
}

.Menu a:hover {
    transition: .3s;
    text-decoration: none;
    color: rgb(14, 7, 112);
}

.link {
    float: left;
    margin: 10px;
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
}


.text {
    z-index: 1;
    float: left;
    color: white;
    margin-top: 20px;
    font-size: $TextS;
    flex-direction: column;
}

@media(max-width: 1000px) {
    .body {
        height: 60px;
        background-size: cover;
        flex-direction: column;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center top;
        background-image: url($url+"/assets/images/statistics.jpg");
    }

    .IndexLogo {
        z-index: 1;
        margin: 10px;
        width: $LogoHW/2;
        margin-left: 7%;
    }

    .text {
        z-index: 1;
        float: left;
        color: white;
        margin-top: 15px;
        font-size: $TextS/2;
        flex-direction: column;
    }
}