@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
  background-image: url("../public/assets/images/batter.png");
}

.elementor {
  width: 100%;
}

.elementor-side {
  width: 20%;
}
.elementor-side > img {
  height: 50px;
  margin-left: calc(50% - 25px);
}
