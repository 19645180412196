@media(max-width:2200px) {
    .mutbMain {
        padding: 50px;
        height: 350px;
        background-color: rgb(255, 255, 255);
    }

    .mutbMCol {
        width: 100%;
        height: 200px;
        padding: 15px;
        border-radius: 8px;
        position: relative;
        box-shadow: 0 0 10px rgb(214, 214, 214);
    }

    .mutbText {
        width: 80%;
        bottom: 20px;
        font-size: 12px;
        color: #7A7A7A;
        margin-left: 10px;
        text-align: center;
        position: absolute;
        align-items: center;
    }

    .peopleImage {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .ShortText {
        width: 60%;
        bottom: 20px;
        font-size: 12px;
        color: #7A7A7A;
        margin-left: 30px;
        text-align: center;
        position: absolute;
        align-items: center;
    }
}

@media(max-width:1000px) {
    .mutbMain {
        padding: 50px;
        height: auto;
        background-color: rgb(255, 255, 255);
    }

    .mutbMCol { 
        margin: 5px;
        height: auto;
        height: 240px;
        font-size: 10px;
        border-radius: 8px;
        position: relative;
        box-shadow: 0 0 10px rgb(214, 214, 214);
    }

    .mutbText {
        width: 100%;
        bottom: 20px;
        font-size: 12px;
        color: #7A7A7A;
        margin-left: 0;
        text-align: center;
        position: unset;
        align-items: center;
    }

    .peopleImage {
        width: 90%;
        margin: 10px 5%;
        align-items: center;
    }

    .peopleImage2 {
        width: 70%;
        margin: 20px 15%;
        align-items: center;
    }
    .ShortText {
        width: 100%;
        bottom: 20px;
        font-size: 12px;
        color: #7A7A7A;
        margin-left: 0;
        text-align: center;
        position: unset;
        align-items: center;
    }
}

@media(max-width: 500px) {
    .mutbMain {
        padding: 5px;
        height: auto;
        background-color: rgb(255, 255, 255);
    }
}