// Header start
// Top style css var
$TopH: 20px;
$TopBgColor: #0C3FA8;
//Top end

// body style css var
$TextS:20px;
$BodyH:100px;
$LogoHW: 77px;
$textcolor: darkblue;
$BodyBgColor: #1149bed8;
//Body end

// Bottom style css var
$MenuH:100%;
$BottomH: 50px;
$BottomBgColor: #1A52C5;
// Bottom end
//Header end

//Footer start
$FooterH:300px;
$FooterBgColor: #1A52C5;
//Footer end

// Other css var
$fontsize: 18px;

$url: "https://mohs.uv.gov.mn";

/* Use the variables */
@import 'Carousel', 'Header', 'TabArticles', 'CardMenu', 'People', 'Mutb', 'Map', 'Article', 'ArticleBottom', 'Footer'